<template>
  <b-row>
    <b-col cols="9">
      <p class="h5 mb-0">
        {{ item.name }}
      </p>
      <p v-if="item.suggestedQuantity" class="h6 text-muted mb-0">
        <span>Obligatoire&nbsp;: {{ requiredString }}</span>
        <br />
        <span>Quantité que l'étudiant doit se procurer&nbsp;: {{ item.suggestedQuantity }}</span>
      </p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  computed: {
    item() {
      const index = this.category.ListCategoryItems.findIndex(
        (item) => item.id === this.itemId
      )
      return this.category.ListCategoryItems[index]
    },
    requiredString() {
      return this.item.required ? 'oui' : 'non'
    },
  },
}
</script>
